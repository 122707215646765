import { useSupabase } from '@wision/supabase';
import { useQuery } from '@tanstack/vue-query';
import { withUseQuery } from '../../helpers';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { type Database } from '../../types';

type Options = {
  onSuccess?: () => void
  onError?: () => void
}

export const useGetUserProfile = () => {
  const supabase = useSupabase();

  return useQuery({
    queryKey: ['userProfile'],
    queryFn: withUseQuery(
      async () => {
        const session = await supabase.auth.getSession();

        return await supabase.from('users').select('*').eq('id', session.data.session?.user.id ?? '').single();
      }
    )
  });
};

export const useSetLanguage = (options?: Options) => {
  const supabase = useSupabase();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (language: string) => {
      const session = await supabase.auth.getSession();

      if (session.error) throw new Error(session.error.message);

      const { error } = await supabase
        .from('users')
        .update({ language: language as Database['public']['Enums']['language'] })
        .eq('id', session.data.session?.user.id ?? '')
        .single();

      if (error) throw new Error(error.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userProfile']
      });
      if (options?.onSuccess) options.onSuccess();
    },
    onError: () => {
      if (options?.onError) options.onError();
    }
  });
}; 
