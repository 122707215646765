import { useSupabase } from '@wision/supabase';
import { useQuery } from '@tanstack/vue-query';
import { withUseQuery } from '../../helpers';

export const useGetUserAccess = () => {
  const supabase = useSupabase();

  return useQuery({
    queryKey: ['userAccess'],
    queryFn: withUseQuery(
      async () => {
        const session = await supabase.auth.getSession();

        return await supabase.rpc('get_user_access', {
          user_id: session.data.session?.user.id ?? ''
        });
      }
    )
  });
};
