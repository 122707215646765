<script setup lang="ts">
import { computed, ref } from 'vue';
import {
  ArrowIcon,
  Direction,
  Avatar,
  SkeletonCircle,
  SkeletonSquare,
  Dropdown,
  SearchIcon,
} from '@wision/ui';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import {
  useGetCurrentOrganization,
  useGetUserAccess,
  useSetCurrentOrganization,
} from '@wision/supabase';

const { t } = useI18n();

const props = defineProps({
  currentOrganizationName: {
    type: String,
    required: true
  },
  currentOrganizationId: {
    type: String,
    required: true
  },
  minimized: {
    type: Boolean,
    default: false,
  },
});

const apiClient = useApi();
const filterValue = ref<string | null>();
const inputRef = ref<HTMLElement | null>(null);
const selectedAccountId = ref<string>();

const { data: userAccess, isLoading: isLoadingUserAccess } = useGetUserAccess();

const filteredOrgs = computed(() =>
  userAccess.value
    ?.filter((org) => org.id !== props.currentOrganizationId)
    .sort((a: { name: string }, b: { name: string }) =>
      a.name > b.name ? 1 : -1
    )
    .filter((org) =>
      org.name
        ?.toLocaleLowerCase()
        .includes(filterValue.value?.toLocaleLowerCase() ?? '')
    )
);

const { mutate: updateOrg } = useSetCurrentOrganization({
  onSuccess: async () => {
    const selectedAccount = userAccess.value?.find(account => account.id === selectedAccountId.value);
    if (selectedAccount?.xtool_id)
      await apiClient.user.updateLoginHistory(selectedAccount.xtool_id);
    location.reload();
  },
  onError: () => {
    toast(t('common.somethingwentwrong'), {
      autoClose: 2000,
      type: 'error',
      theme: 'dark',
    });
  },
});

const handleOnClickOption = (orgId: string) => {
  selectedAccountId.value = orgId;
  updateOrg(orgId);
};

const focusInput = () => inputRef.value?.focus();
</script>

<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="isLoadingUserAccess"
      class="flex items-center justify-center gap-2"
    >
      <SkeletonCircle class="w-8 h-8" />
      <SkeletonSquare class="w-36 h-4" />
    </div>
    <div v-else-if="(filteredOrgs && filteredOrgs?.length > 0) || filterValue">
      <Dropdown :on-open="focusInput">
        <template #button="{ isOpen }">
          <div class="cursor-pointer">
            <div
              class="relative flex gap-2 items-center"
              :class="{
                'mr-10': !props.minimized,
              }"
            >
              <div
                class="w-[29px] h-[29px] text-[10px]"
                :class="{
                  'w-[35px] h-[35px]': props.minimized,
                }"
              >
                <Avatar :name="props.currentOrganizationName" />
              </div>
              <p
                v-if="!props.minimized"
                class="truncate text-xs max-w-[160px] text-left"
              >
                {{ props.currentOrganizationName }}
              </p>
            </div>
            <div
              v-if="!props.minimized"
              class="absolute right-4 bottom-0 top-0 flex items-center w-2"
            >
              <ArrowIcon :direction="isOpen ? Direction.Up : Direction.Down" />
            </div>
          </div>
        </template>
        <template #default="{ close }">
          <div class="w-full max-h-[300px] min-w-[250px]">
            <div class="p-2 border-b-[1px] border-gray-500">
              <div class="px-1 rounded-lg flex items-center">
                <SearchIcon />
                <input
                  ref="inputRef"
                  v-model="filterValue"
                  placeholder="Search"
                  class="bg-transparent w-full h-full px-4 py-2 cursor-pointer outline-none"
                />
              </div>
            </div>
            <div class="overflow-auto scroll-bar-thin max-h-[238px] my-4">
              <div
                v-for="org in filteredOrgs"
                :key="org.id"
                class="w-full overflow-hidden truncate py-3 px-4 hover:bg-gray-600 cursor-pointer select-none rounded-md"
                @click="
                  () => {
                    close();
                    handleOnClickOption(org.id);
                  }
                "
              >
                <div
                  class="relative flex gap-2 items-center"
                  :class="{
                    'mr-10': !props.minimized,
                  }"
                >
                  <div class="w-8 h-8 text-[10px]">
                    <Avatar :name="org.name" />
                  </div>
                  <p class="truncate text-[12px] max-w-[200px] text-left">
                    {{ org.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Dropdown>
    </div>
    <div v-else-if="props.currentOrganizationName">
      <div>
        <div
          class="relative flex gap-2 items-center"
          :class="{
            'mr-10': !props.minimized,
          }"
        >
          <div
            class="w-[29px] h-[29px] text-[10px]"
            :class="{
              'w-[35px] h-[35px]': props.minimized,
            }"
          >
            <Avatar :name="props.currentOrganizationName" />
          </div>
          <p
            v-if="!props.minimized"
            class="truncate text-xs max-w-[160px] text-left"
          >
            {{ props.currentOrganizationName }}
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>
